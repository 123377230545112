import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom"
import ReactGA from 'react-ga4';

import App from "./App";

ReactGA.initialize('G-77BJ2YMC0K', { debug: true });
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
     <BrowserRouter>
        <App/>
    </BrowserRouter>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to  an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
